import { gql } from '@apollo/client';

export const LIST_MENUS = gql`
    query ($input: GetMenusDTO!) {
        listMenus(input: $input) {
            message
            menus {
                _id
                title
                menuPosition
                status
                updatedAt
                menuItems {
                    name
                    children {
                        name
                    }
                }
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const LIST_MENU = gql`
    query ($id: String!) {
        listMenu(id: $id) {
            message
            menu {
                _id
                title
                logo
                imageAltText
                status
                menuPosition
                menuItems {
                    id
                    name
                    linkType
                    link
                    iconType
                    icon
                    index
                    children {
                        id
                        name
                        linkType
                        link
                        iconType
                        icon
                        index
                    }
                }
                updatedAt
            }
        }
    }
`;
