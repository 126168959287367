import { gql } from '@apollo/client';

export const GET_MAIN_CATEGORY_BY_ID = gql`
    query ($id: String!) {
        GetMainCategory(id: $id) {
            mainCategory {
                _id
                title
                metaTitle
                metaDescription
                productConditions
                filterOptions {
                    productCategoryId
                    itemStyleId
                    containTitle
                    conditionTitle
                }
                conditionType
                createdAt
                updatedAt
            }
        }
    }
`;

export const GET_ALL_MAIN_CATEGORIES = gql`
    query ($input: GetMainCategoriesDto!) {
        GetAllMainCategories(input: $input) {
            mainCategories {
                _id
                title
                metaTitle
                metaDescription
                productConditions
                status
                filterOptions {
                    containTitle
                    conditionTitle
                }
                createdAt
                updatedAt
            }
            pagination {
                total
            }
        }
    }
`;

export const GET_ALL_PRODUCT_CATEGORIES = gql`
    query ($input: GetProductCategoriesDto!) {
        GetAllProductCategories(input: $input) {
            message
            productCategories {
                _id
                title
                status
                mainCategory
                createdAt
                updatedAt
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_PRODUCT_CATEGORY_BY_ID = gql`
    query ($id: String!) {
        GetProductCategory(id: $id) {
            message
            mainCategory {
                _id
                title
                status
                createdAt
                updatedAt
            }
        }
    }
`;

export const GET_PRODUCT_CATEGORIES_BY_MAIN_CATEGORY = gql`
    query ($id: String!) {
        GetProductCategoriesByMainCategory(id: $id) {
            message
            productCategories {
                _id
                title
                status
                createdAt
                updatedAt
            }
        }
    }
`;

export const GET_ITEM_STYLES_BY_PRODUCT_CATEGORY = gql`
    query ($id: String!) {
        GetItemStylesByProductCategory(id: $id) {
            message
            itemStyles {
                _id
                title
                status
                createdAt
                updatedAt
            }
        }
    }
`;

export const GET_ALL_ITEM_STYLES = gql`
    query ($input: GetItemStylesDto!) {
        GetAllItemStyles(input: $input) {
            message
            itemStyles {
                _id
                title
                createdAt
                updatedAt
            }
        }
    }
`;

export const GET_ALL_ITEMS_BY_PRODUCT_CATEGORY_AND_ITEM_STYLE = gql`
    query FindItemByProductCategoryAndItemStyle($input: FilterItemByProdCategoryAndItemStyleDto!) {
        FindItemByProductCategoryAndItemStyle(input: $input) {
            items {
                images
                name
                status
                _id
                designerInfo {
                    name
                }
            }
            message
        }
    }
`;
