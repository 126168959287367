import { HeadCell1 } from 'types';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'name',
        numeric: false,
        label: 'Name',
        align: 'center',
        sort: false
    },
    {
        id: 'details',
        numeric: false,
        label: 'Details',
        align: 'center',
        sort: false
    },
    {
        id: 'date',
        numeric: false,
        label: 'Date Published',
        align: 'center',
        sort: false
    },
    {
        id: 'time',
        numeric: false,
        label: 'Time',
        align: 'center',
        sort: false
    }
];
