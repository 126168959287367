// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import moment from 'moment';
import { displayBusinessNameOrUserName } from 'utils/commonHelper';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notification }) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notification &&
                notification.map((row) => (
                    <Link href={row.redirectTo}>
                        <ListItemWrapper
                            sx={{
                                '&:hover': {
                                    background: '#FBE9E7'
                                }
                            }}
                        >
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <Avatar alt="John Doe" src={row.userData.image} />
                                </ListItemAvatar>
                                <Typography variant="h5">
                                    {displayBusinessNameOrUserName(
                                        row?.userData?.firstName,
                                        row?.userData?.lastName,
                                        row?.userData?.businessName
                                    )}
                                </Typography>
                            </ListItem>
                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        {' '}
                                        {row.notificationMessage.substring(0, row.notificationMessage.indexOf('.'))}.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption" display="block">
                                        {moment(row.date).format('h:mm a')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                    </Link>
                ))}

            <Divider />
        </List>
    );
};

export default NotificationList;
