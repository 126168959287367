import { gql } from '@apollo/client';

export const GET_ADMIN_LIST = gql`
    query ($input: GetAdminListDTO!) {
        getAdminList(input: $input) {
            message
            adminList {
                _id
                firstname
                lastname
                email
                phone
                status
                role
                image
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ADMIN = gql`
    query ($id: String!) {
        getAdmin(id: $id) {
            message
            admin {
                _id
                firstname
                lastname
                email
                role
                status
                phone
                image
            }
        }
    }
`;

export const GET_USER_PROFILE = gql`
    query {
        getUserProfile {
            _id
            firstname
            lastname
            image
            email
            phone
            status
            role
        }
    }
`;

export const ISSUE_REFUND = gql`
    mutation ($input: RefundDto!) {
        RefundOrderToCustomer(input: $input) {
            message
            refundInfo {
                refundType
                refundResponse {
                    payment_intent
                    amount
                }
                paymentIntentId
            }
        }
    }
`;
