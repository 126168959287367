/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';
// material-ui
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Stack,
    Typography,
    Menu,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Button,
    Grid,
    Pagination,
    PaginationItem,
    Radio,
    Link
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// project imports

import Spinner from 'components/spinner';
import FailureLoad from 'components/spinner/fail';

import useTable from 'hooks/pageManagement/useTable';
import useGQL from 'hooks//notifications/useGQL';
import useSnackbar from 'hooks/common/useSnackbar';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useDebouncedSearch from 'hooks/common/useDebounceSearch';

import { ArrangementOrder, ArrangementOrder1, HeadCell1, Order, Page } from 'types';
import { Notifications } from 'types/gql/notifications';
import { headCells } from 'constants/notifications';

// assets
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CustomMainCard } from './index.styles';
import moment from 'moment';

const NotificationList = () => {
    const { TableContainer, TableHead } = useTable();

    const [rows, setRows] = useState<Notifications[]>([]);
    const [orderMeta, setOrderMeta] = useState<Order>({ order: 'desc', orderBy: 'createdAt' });
    const [pageMeta, setPageMeta] = useState<Page>({ page: 0, count: 1, rowsPerPage: 10 });
    const [meta, setMeta] = useState<{ limit: number; skip: number }>({ limit: 10, skip: 0 });

    const { GET_NOTIFICATIONS } = useGQL();
    const { error, loading, data, refetch } = GET_NOTIFICATIONS({
        limit: 10,
        skip: 0,
        order: 'desc',
        orderBy: 'createdAt'
    });

    const rowsPerPageOptions = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 25, label: 25 }
    ];

    useEffect(() => {
        if (data?.GetAllAdminNotifications) {
            setRows(data?.GetAllAdminNotifications.notifications);
            setPageMeta({ ...pageMeta, count: data.GetAllAdminNotifications.pagination.total });
        }
    }, [data]);

    useEffect(() => {
        /* set page meta */
        const { page, rowsPerPage } = pageMeta;
        const { order, orderBy } = orderMeta;
        const limit = rowsPerPage;
        const skip = page > 0 ? limit * page : 0;
        setMeta({ limit, skip });
        refetch({ input: { limit, skip, order, orderBy } });
    }, [pageMeta.rowsPerPage, pageMeta.page]);

    useEffect(() => {
        /* handle refetch here */
        handleRefetch();
    }, [orderMeta.orderBy, orderMeta.order]);

    const handleRefetch = () => {
        refetch({
            input: {
                limit: meta?.limit,
                skip: meta?.skip,
                order: orderMeta.order,
                orderBy: orderMeta.orderBy
            }
        });
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = orderMeta.orderBy === property && orderMeta.order === 'asc';
        setOrderMeta({ ...orderMeta, order: isAsc ? 'desc' : 'asc', orderBy: property });
    };

    const handleChangePage = (event, newPage) => {
        setPageMeta({ ...pageMeta, page: newPage - 1 });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setPageMeta({ ...pageMeta, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    // Pagination
    const [anchor, setAnchor] = useState(null);

    const handleClickPage = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleClosePage = () => {
        setAnchor(null);
    };
    const handlePageMenuItemClick = (event) => {
        handleChangeRowsPerPage(event);
        setAnchor(null);
    };

    return (
        <CustomMainCard title="Notifications" content={false}>
            <>
                {/* table */}
                {loading ? (
                    <Spinner />
                ) : error ? (
                    <FailureLoad />
                ) : (
                    <>
                        <TableContainer>
                            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                <TableHead headCells={headCells} />
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">
                                                {row.userData.firstName} {row.userData.lastName}
                                            </TableCell>
                                            <Link href={row.redirectTo}>
                                                <TableCell align="center">
                                                    {row.notificationMessage.substring(0, row.notificationMessage.indexOf('.'))}.
                                                </TableCell>
                                            </Link>
                                            <TableCell align="center">{moment(row.date).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell align="center">{moment(row.date).format('h:mm a')}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* table pagination */}
                        <Grid item xs={12} sx={{ p: 3 }}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Pagination
                                        count={Math.ceil(pageMeta.count / pageMeta.rowsPerPage)}
                                        color="primary"
                                        onChange={handleChangePage}
                                        defaultPage={pageMeta.page + 1}
                                        page={pageMeta.page + 1}
                                        renderItem={(item) => (
                                            <PaginationItem components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
                                        )}
                                        hidePrevButton={pageMeta.page === 0 ?? true}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        size="large"
                                        endIcon={<ExpandMoreIcon />}
                                        onClick={handleClickPage}
                                        className="pagination-button"
                                    >
                                        <span>Show&nbsp;</span> {pageMeta.rowsPerPage} per page
                                    </Button>
                                    <Menu
                                        id="menu-user-list-style1"
                                        anchorEl={anchor}
                                        keepMounted
                                        open={Boolean(anchor)}
                                        onClose={handleClosePage}
                                        variant="selectedMenu"
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                    >
                                        {rowsPerPageOptions.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                onClick={(event) => handlePageMenuItemClick(event)}
                                            >
                                                {option.label} rows
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </>
        </CustomMainCard>
    );
};

export default NotificationList;
