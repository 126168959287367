import { useQuery } from '@apollo/client';

import { GET_ALL_NOTIFICATIONS } from 'grapqhl/notifications/query';

import { NotificationsResponse, GetNotificationsDTO } from 'types/gql/notifications';

const useGQL = () => {
    const GET_NOTIFICATIONS = (input: GetNotificationsDTO) =>
        useQuery<NotificationsResponse, { input: GetNotificationsDTO }>(GET_ALL_NOTIFICATIONS, {
            variables: { input },
            notifyOnNetworkStatusChange: true
        });

    return {
        GET_NOTIFICATIONS
    };
};

export default useGQL;
