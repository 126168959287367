import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import NotificationList from 'views/notifications';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/default')));

// page management routing
const PageList = Loadable(lazy(() => import('views/pageManagement')));
const AdvancedPageList = Loadable(lazy(() => import('views/pageManagement/advancedPage')));
const AddPage = Loadable(lazy(() => import('views/pageManagement/forms/addPage')));
const EditPage = Loadable(lazy(() => import('views/pageManagement/advancedPageEdit')));

// menu routing
const MenuList = Loadable(lazy(() => import('views/menu/menusList')));
const AddMenu = Loadable(lazy(() => import('views/menu/addMenu')));
const EditMenu = Loadable(lazy(() => import('views/menu/editMenu')));

// User managemenet routing
const AdminList = Loadable(lazy(() => import('views/userManagement/admins')));
const AdminAdd = Loadable(lazy(() => import('views/userManagement/admins/add-forms/index')));
const AdminEdit = Loadable(lazy(() => import('views/userManagement/admins/edit-forms/index')));
const AdminProfile = Loadable(lazy(() => import('views/profile')));
const AdminAccountProfile = Loadable(lazy(() => import('views/profile/user-profile')));
const AdminChangePassword = Loadable(lazy(() => import('views/profile/change-password')));

const AppUserList = Loadable(lazy(() => import('views/userManagement/appUsers')));
const AppUserProfile = Loadable(lazy(() => import('views/userManagement/appUsers/profile')));
const AddAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/AddAppUser')));
const EditAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/EditAppUser')));

/* faq */
const FAQList = Loadable(lazy(() => import('views/FAQ')));
const AddFAQ = Loadable(lazy(() => import('views/FAQ/forms/addForm')));
const AddFAQV1 = Loadable(lazy(() => import('views/FAQ/forms/addForm/v1')));
const EditFAQ = Loadable(lazy(() => import('views/FAQ/forms/editForm')));
const EditFAQV1 = Loadable(lazy(() => import('views/FAQ/forms/editForm/v1/EditFAQ')));
const ManageFAQ = Loadable(lazy(() => import('views/FAQ/tables')));

/* testimonials */
const TestimonialsList = Loadable(lazy(() => import('views/testimonials')));
const AddTestimonials = Loadable(lazy(() => import('views/testimonials/forms/AddTestimonials')));
const EditTestimonials = Loadable(lazy(() => import('views/testimonials/forms/EditTestimonials')));

/* email template */
const EmailTemplateList = Loadable(lazy(() => import('views/emailTemplate')));
const AddEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/addTemplate')));
const EditEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/editTemplate')));

// Products managemenet routing
const DesignersList = Loadable(lazy(() => import('views/designers')));
const AddDesigners = Loadable(lazy(() => import('views/designers/forms/AddDesigners')));
const EditDesigners = Loadable(lazy(() => import('views/designers/forms/EditDesigners')));

const ListedItemsList = Loadable(lazy(() => import('views/listedItems')));
const EditListedItems = Loadable(lazy(() => import('views/listedItems/forms/editListedItem')));

// Managemenet routing
const DiscountsList = Loadable(lazy(() => import('views/discounts')));
const AddDiscounts = Loadable(lazy(() => import('views/discounts/forms/AddDiscounts')));
const EditDiscounts = Loadable(lazy(() => import('views/discounts/forms/EditDiscounts')));

const OrdersList = Loadable(lazy(() => import('views/orders')));
const OrderDetail = Loadable(lazy(() => import('views/orders/orderDetails')));
const EditOrder = Loadable(lazy(() => import('views/orders/editOrder')));

const ReviewsList = Loadable(lazy(() => import('views/reviews')));
const EditReview = Loadable(lazy(() => import('views/reviews/editReview')));

const ReportsList = Loadable(lazy(() => import('views/reports')));
const ViewReport = Loadable(lazy(() => import('views/reports/viewReport')));

const MainCategoriesList = Loadable(lazy(() => import('views/mainCategories')));
// const AddCategories = Loadable(lazy(() => import('views/categories/forms/AddCategories')));
const EditMainCategories = Loadable(lazy(() => import('views/mainCategories/forms/EditMainCategories')));

// Mock component
const MockComponent = Loadable(lazy(() => import('views/mock')));

// Error routing
const ErrorPage = Loadable(lazy(() => import('views/pages/maintenance/error')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'default',
            element: <DashboardDefault />
        },

        {
            path: 'page-management/add',
            element: <AddPage />
        },
        {
            path: 'page-management/edit/:id',
            element: <EditPage />
        },
        {
            path: 'page-management/list',
            element: <PageList />
        },
        {
            path: 'page-management/add/advance',
            element: <AdvancedPageList />
        },
        {
            path: 'menu/list',
            element: <MenuList />
        },
        {
            path: 'notifications/list',
            element: <NotificationList />
        },
        {
            path: 'menu/add',
            element: <AddMenu />
        },
        {
            path: 'menu/edit/:id',
            element: <EditMenu />
        },
        {
            path: 'orders/list',
            element: <OrdersList />
        },
        {
            path: 'orders/:id',
            element: <OrderDetail />
        },
        {
            path: 'orders/edit/:id',
            element: <EditOrder />
        },
        {
            path: 'reviews/list',
            element: <ReviewsList />
        },
        {
            path: 'reviews/:id',
            element: <EditReview />
        },
        {
            path: 'reports/list',
            element: <ReportsList />
        },
        {
            path: 'reports/view/:id',
            element: <ViewReport />
        },
        {
            path: 'app-user/list',
            element: <AppUserList />
        },
        {
            path: 'app-user/add',
            element: <AddAppUser />
        },
        {
            path: 'app-user/edit/:id',
            element: <EditAppUser />
        },
        {
            path: 'app-user/profile/:id',
            element: <AppUserProfile />
        },
        {
            path: 'admin/list',
            element: <AdminList />
        },
        {
            path: 'admin/add',
            element: <AdminAdd />
        },
        {
            path: 'admin/edit/:id',
            element: <AdminEdit />
        },
        {
            path: 'admin/profile',
            element: <AdminProfile />
        },
        {
            path: 'admin/account-profile',
            element: <AdminAccountProfile />
        },
        {
            path: 'admin/change-password',
            element: <AdminChangePassword />
        },
        {
            path: 'faq/list',
            element: <FAQList />
        },
        {
            path: 'faq/add',
            element: <AddFAQV1 />
        },
        {
            path: 'faq/edit/:id',
            element: <EditFAQV1 />
        },
        {
            path: 'faq/manage-faq',
            element: <ManageFAQ />
        },
        {
            path: 'email-template/list',
            element: <EmailTemplateList />
        },
        {
            path: 'email-template/add',
            element: <AddEmailTemplate />
        },
        {
            path: 'email-template/edit/:id',
            element: <EditEmailTemplate />
        },
        {
            path: '/testimonials/list',
            element: <TestimonialsList />
        },
        {
            path: 'testimonials/add',
            element: <AddTestimonials />
        },
        {
            path: 'testimonials/edit/:id',
            element: <EditTestimonials />
        },
        {
            path: 'mock',
            element: <MockComponent />
        },
        {
            path: '/designers/list',
            element: <DesignersList />
        },
        {
            path: 'designers/add',
            element: <AddDesigners />
        },
        {
            path: 'designers/edit/:id',
            element: <EditDesigners />
        },
        {
            path: '/listed-items/list',
            element: <ListedItemsList />
        },
        {
            path: 'listed-items/edit/:id',
            element: <EditListedItems />
        },
        {
            path: '/discounts/list',
            element: <DiscountsList />
        },
        {
            path: 'discounts/add',
            element: <AddDiscounts />
        },
        {
            path: 'discounts/edit/:id',
            element: <EditDiscounts />
        },
        {
            path: '/categories/list',
            element: <MainCategoriesList />
        },
        // {
        //     path: 'categories/add',
        //     element: <AddCategories />
        // },
        {
            path: 'categories/edit/:id',
            element: <EditMainCategories />
        }
    ]
};

export default MainRoutes;
