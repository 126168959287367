import { useMutation, useQuery } from '@apollo/client';

import {
    FORGOT_PASSWORD,
    LOGIN,
    REGISTER,
    RESET_PASSWORD,
    GET_ADMIN_LIST,
    GET_ADMIN as ADMIN,
    UPDATE_ADMIN as ADMIN_UPDATE,
    DELETE_ADMIN as ADMIN_DELETE,
    GET_USER_PROFILE,
    CHANGE_PASSWORD,
    CREATE_PAGE as CREATE_PG,
    UPDATE_PAGE as UPDATE_PG,
    DELETE_PAGE as DELETE_PG,
    LIST_PAGE as GET_LIST_PAGE,
    LIST_PAGES as GET_LIST_PAGES,
    CREATE_MENU as CREATE_MENUS,
    LIST_MENUS as GET_LIST_MENUS,
    DELETE_MENU as MENU_DELETE,
    UPDATE_MENU as MENU_UPDATE,
    UPDATE_MENU_STATUS as MENU_STATUS_UPDATE,
    LIST_MENU as GET_LIST_MENU,
    GET_ALL_PRODUCT_CATEGORIES
} from '../grapqhl';

type GetAdminListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    status?: string;
};

type GetListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    status?: string;
};

const useGQL = () => {
    const ADMIN_LOGIN = () => useMutation(LOGIN);
    const ADMIN_REGISTER = () => useMutation(REGISTER);
    const FORGOT_PASS = () => useMutation(FORGOT_PASSWORD);
    const RESET_PASS = () => useMutation(RESET_PASSWORD);
    const ADMIN_CHANGE_PASSWORD = () => useMutation(CHANGE_PASSWORD);

    /* admins */
    const GET_ALL_ADMINS = (input: GetAdminListDTO = {}) => useQuery(GET_ADMIN_LIST, { fetchPolicy: 'network-only', variables: { input } });
    const GET_ADMIN = (id: String) => useQuery(ADMIN, { variables: { id } });
    const UPDATE_ADMIN = () => useMutation(ADMIN_UPDATE);
    const DELETE_ADMIN = () => useMutation(ADMIN_DELETE);
    const GET_ADMIN_PROFILE = () => useQuery(GET_USER_PROFILE);

    const CREATE_PAGE = () => useMutation(CREATE_PG);
    const UPDATE_PAGE = () => useMutation(UPDATE_PG);
    const DELETE_PAGE = () => useMutation(DELETE_PG);
    const LIST_PAGES = (input: GetListDTO = {}) =>
        useQuery(GET_LIST_PAGES, { fetchPolicy: 'network-only', variables: { input }, notifyOnNetworkStatusChange: true });
    const LIST_PAGE = (id: String) => useQuery(GET_LIST_PAGE, { variables: { id } });

    const CREATE_MENU = () => useMutation(CREATE_MENUS);
    const LIST_MENUS = (input: GetListDTO = {}) => useQuery(GET_LIST_MENUS, { variables: { input }, notifyOnNetworkStatusChange: true });
    const DELETE_MENU = () => useMutation(MENU_DELETE);
    const LIST_MENU = (id: String) => useQuery(GET_LIST_MENU, { variables: { id } });
    const UPDATE_MENU = () => useMutation(MENU_UPDATE);
    const UPDATE_MENU_STATUS = () => useMutation(MENU_STATUS_UPDATE);

    const LIST_PRODUCT_CATEGORIES = (input: GetListDTO = {}) =>
        useQuery(GET_ALL_PRODUCT_CATEGORIES, { variables: { input }, notifyOnNetworkStatusChange: true });
    return {
        ADMIN_LOGIN,
        ADMIN_REGISTER,
        FORGOT_PASS,
        RESET_PASS,
        GET_ALL_ADMINS,
        GET_ADMIN,
        UPDATE_ADMIN,
        DELETE_ADMIN,
        GET_ADMIN_PROFILE,
        ADMIN_CHANGE_PASSWORD,
        CREATE_PAGE,
        UPDATE_PAGE,
        DELETE_PAGE,
        LIST_PAGES,
        LIST_PAGE,
        CREATE_MENU,
        LIST_MENUS,
        DELETE_MENU,
        LIST_MENU,
        UPDATE_MENU,
        UPDATE_MENU_STATUS,
        LIST_PRODUCT_CATEGORIES
    };
};

export default useGQL;
