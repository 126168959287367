// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */

        <svg width="200" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 284.13 1212.45 110.65">
            <g transform="matrix(1.33333 0 0 -1.33333 0 690.987)">
                <g fill="#100f0d" fillOpacity="1" fillRule="nonzero" stroke="none" transform="scale(.1)">
                    <path d="M479.566 2257.45c176.817 0 240.372 132.89 229.969 285.44-17.308 246.1-156.008 478.39-355.902 478.39-157.192 0-246.192-122.48-233.469-309.66 16.172-231.15 162.961-454.17 359.402-454.17zM0 2641.07c0 228.88 168.73 410.28 413.691 410.28 195.305 0 412.555-134.02 412.555-417.19 0-281.94-218.383-412.55-412.555-412.55C217.25 2221.61 0 2350.99 0 2641.07" />
                    <path d="M1005.62 2908.06v-458.8c0-141.02 27.71-181.4 87.81-199.94v-16.18H938.605v16.18c38.118 12.67 46.204 47.38 46.204 130.61v515.37c0 83.23-10.403 117.94-60.106 130.61v16.17h130.617l485.38-607.86v391.75c0 141.02-27.76 181.5-87.86 199.94v16.17h156v-16.17c-38.11-12.67-46.2-47.38-46.2-130.61v-671.42h-15.04l-541.98 684.18" />
                    <path d="M1732.7 2249.32c38.11 12.67 46.2 47.38 46.2 130.61v515.37c0 83.23-8.09 117.94-46.2 130.61v16.17h554.7l12.68-162.96-14.99-3.4c-46.25 113.21-119.03 147.92-248.46 147.92h-135.25v-346.73h110.94c141.02 0 181.45 27.71 199.94 87.87h16.17V2570.6h-16.17c-18.49 60.06-58.92 87.77-199.94 87.77h-110.94v-348.99c0-36.98 20.81-57.7 57.79-57.7h110.94c116.76 0 196.49 34.62 240.37 152.46l15.04-2.27-8.09-168.73H1732.7v16.18" />
                    <path d="M2989.04 3025.91v16.17h209.21v-16.17c-38.16-12.67-21.99-42.75 9.22-120.21l56.66-137.52 50.83 136.38c28.9 77.47 19.63 112.08-19.67 121.35v16.17h134.07v-16.17c-34.67-12.67-63.56-43.88-93.59-121.35l-61.24-164.09 147.92-359.4 190.68 523.49c28.89 77.47 19.62 112.08-19.68 121.35v16.17h134.07v-16.17c-34.66-12.67-63.56-43.88-91.32-121.35l-243.82-671.42h-41.62l-152.51 373.31-151.42-373.31h-43.88l-256.55 671.42c-28.9 78.6-57.79 108.68-84.37 121.35v16.17h209.17v-16.17c-38.17-12.67-23.13-42.75 6.9-121.35l202.26-525.76 106.31 257.63-109.76 269.27c-31.21 77.46-61.24 107.54-87.87 120.21" />
                    <path d="M4097.61 2553.3l-149.06 357.03-144.47-357.03zm-486.52-303.98c34.67 12.67 65.88 42.75 97.09 120.21l216.06 536.17c9.27 23.17 8.09 41.61-2.31 62.42l-14.99 32.35 113.26 50.88 286.57-682.96c32.35-76.32 62.42-106.4 89-119.07v-16.18h-210.34v16.18c38.16 12.67 21.99 42.75-10.41 119.07l-69.32 166.37h-308.57l-67.01-165.23c-31.21-77.46-24.26-110.94 15.04-120.21v-16.18h-134.07v16.18" />
                    <path d="M4704.43 2666.51c131.8 0 181.45 73.96 181.45 162.96 0 93.63-60.06 194.17-204.53 194.17-16.17 0-33.53-1.23-50.88-3.5v-353.63zm171.05-243.83c-69.33 168.73-75.1 225.38-176.82 225.38h-68.19v-268.13c0-83.23 8.09-117.94 46.25-130.61v-16.18h-214.93v16.18c38.11 12.67 46.2 47.38 46.2 130.61v515.37c0 83.23-8.09 117.94-46.2 130.61v16.17h242.64c251.91 0 303.93-91.27 303.93-184.9 0-106.31-72.82-186.04-226.52-204.58 130.62-14.94 156.06-69.32 217.35-223.01 61.19-153.7 73.96-181.41 116.71-181.41 5.77 0 11.54 0 17.31 1.14v-16.18c-26.58-6.9-63.56-8.13-83.23-8.13-95.91 0-118.98 62.42-174.5 197.67" />
                    <path d="M5448.82 2251.68h62.42c186.04 0 288.94 98.18 288.94 345.5 0 236.92-122.48 426.46-380.21 426.46h-46.25v-687.69c0-54.29 11.54-84.27 75.1-84.27zm-243.83-2.36c38.21 12.67 46.25 47.38 46.25 130.61v515.37c0 83.23-8.04 117.94-46.25 130.61v16.17h288.94c332.83 0 441.5-218.38 442.63-388.25 1.14-250.82-162.96-420.69-417.19-420.69h-314.38v16.18" />
                    <path d="M6321.6 2666.51c131.75 0 181.5 73.96 181.5 162.96 0 93.63-60.16 194.17-204.58 194.17-16.17 0-33.48-1.23-50.88-3.5v-353.63zm171.09-243.83c-69.42 168.73-75.19 225.38-176.86 225.38h-68.19v-268.13c0-83.23 8.13-117.94 46.25-130.61v-16.18h-214.98v16.18c38.21 12.67 46.25 47.38 46.25 130.61v515.37c0 83.23-8.04 117.94-46.25 130.61v16.17h242.69c251.96 0 303.98-91.27 303.98-184.9 0-106.31-72.83-186.04-226.52-204.58 130.61-14.94 155.96-69.32 217.25-223.01 61.28-153.7 73.96-181.41 116.71-181.41 5.77 0 11.54 0 17.31 1.14v-16.18c-26.58-6.9-63.56-8.13-83.14-8.13-96 0-119.07 62.42-174.5 197.67" />
                    <path d="M7252.07 2257.45c176.77 0 240.33 132.89 229.92 285.44-17.3 246.1-155.96 478.39-355.9 478.39-157.19 0-246.19-122.48-233.42-309.66 16.17-231.15 162.86-454.17 359.4-454.17zm-479.61 383.62c0 228.88 168.73 410.28 413.69 410.28 195.31 0 412.55-134.02 412.55-417.19 0-281.94-218.38-412.55-412.55-412.55-196.44 0-413.69 129.38-413.69 419.46" />
                    <path d="M7969.93 2251.68h32.34c166.37 0 239.2 87.77 239.2 210.25 0 138.75-77.46 236.92-249.6 236.92h-82.09v-346.63c0-83.23 10.4-100.54 60.15-100.54zm42.75 465.71c84.36 0 135.15 64.69 135.15 145.56 0 101.67-72.73 160.69-190.67 160.69h-47.38v-306.25zm-271.63-468.07c38.21 12.67 46.25 47.38 46.25 130.61v515.37c0 83.23-8.04 117.94-46.25 130.61v16.17h285.44c189.53 0 258.86-58.92 258.86-152.55 0-78.6-49.65-150.2-195.31-175.64 190.68-5.77 287.81-93.63 288.94-209.21 2.27-135.15-102.8-271.54-387.11-271.54h-250.82v16.18" />
                    <path d="M8500.52 2249.32c38.21 12.67 46.25 47.38 46.25 130.61v515.37c0 83.23-8.04 117.94-46.25 130.61v16.17h554.71l12.77-162.96-15.04-3.4c-46.25 113.21-119.08 147.92-248.46 147.92h-135.25v-346.73h110.94c141.02 0 181.5 27.71 199.94 87.87h16.18V2570.6h-16.18c-18.44 60.06-58.92 87.77-199.94 87.77h-110.94v-348.99c0-36.98 20.81-57.7 57.79-57.7h110.94c116.71 0 196.44 34.62 240.42 152.46l14.94-2.27-8.04-168.73h-584.78v16.18" />
                </g>
            </g>
        </svg>

        // <svg width="126" height="39" viewBox="0 0 126 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <g clipPath="url(#clip0_25_5424)">
        //         <path
        //             d="M28.1795 26.2548C27.7217 29.3512 26.9137 31.8483 25.7555 33.7461C23.8466 36.8858 20.7458 38.4557 16.4532 38.4557C9.92849 38.4557 6.01634 34.3887 4.71678 26.2548H1.01001C1.55729 28.9412 2.6838 31.4793 4.31278 33.6962C6.97586 37.2021 11.0227 38.9551 16.4532 38.9551C21.8838 38.9551 25.9323 37.1755 28.5987 33.6163C30.199 31.4151 31.3171 28.9078 31.8813 26.2548H28.1795Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M4.77234 12.3707C6.11903 4.47983 10.0312 0.532719 16.5088 0.529389C20.8317 0.529389 23.9324 2.08426 25.8111 5.19401C26.912 7.0219 27.6897 9.41414 28.1442 12.3707H31.8459C31.2863 9.78833 30.1796 7.35255 28.5987 5.22397C25.9356 1.74132 21.9056 0 16.5088 0C11.1119 0 7.07182 1.74132 4.38853 5.22397C2.77794 7.34384 1.64646 9.78104 1.07062 12.3707H4.77234Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M1.51503 21.8598C1.05386 21.6139 0.669621 21.2477 0.404012 20.801C0.142614 20.3386 0.00537109 19.8176 0.00537109 19.2878C0.00537109 18.758 0.142614 18.237 0.404012 17.7745C0.669621 17.3279 1.05386 16.9617 1.51503 16.7158C1.99938 16.4544 2.54404 16.3219 3.09572 16.3312C3.64521 16.3215 4.18794 16.4522 4.67135 16.7108C5.13112 16.9568 5.5137 17.3231 5.77732 17.7695C6.04196 18.2328 6.18103 18.7557 6.18103 19.2878C6.18103 19.8198 6.04196 20.3428 5.77732 20.806C5.5137 21.2525 5.13112 21.6187 4.67135 21.8648C4.18794 22.1234 3.64521 22.2541 3.09572 22.2444C2.54404 22.2537 1.99938 22.1212 1.51503 21.8598V21.8598ZM4.35824 21.3604C4.72764 21.1583 5.03347 20.8594 5.24201 20.4964C5.45064 20.118 5.55995 19.6938 5.55995 19.2628C5.55995 18.8318 5.45064 18.4077 5.24201 18.0292C5.03347 17.6663 4.72764 17.3673 4.35824 17.1652C3.97245 16.953 3.53713 16.8445 3.09572 16.8506C2.6543 16.8445 2.21898 16.953 1.83319 17.1652C1.46031 17.3644 1.15213 17.664 0.944373 18.0292C0.728337 18.4053 0.614758 18.8303 0.614758 19.2628C0.614758 19.6953 0.728337 20.1204 0.944373 20.4964C1.15213 20.8617 1.46031 21.1612 1.83319 21.3604C2.21947 21.5709 2.65475 21.6776 3.09572 21.67C3.53273 21.6844 3.96603 21.5863 4.35319 21.3854L4.35824 21.3604Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M14.1655 16.3811V22.1944H13.6353L9.87293 17.4798V22.1994H9.25177V16.3811H9.75678L13.5444 21.1007V16.3811H14.1655Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M21.9174 21.665V22.1944H17.7612V16.3811H21.8013V16.9105H18.3824V18.9781H21.4124V19.4775H18.3824V21.64L21.9174 21.665Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M37.0172 16.3811L35.0325 22.1944H34.3811L32.6539 17.2351L30.9268 22.1944H30.3006L28.2805 16.3811H28.9169L30.6137 21.3753L32.3711 16.3811H32.9519L34.6841 21.4053L36.4213 16.3811H37.0172Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M44.1126 21.665V22.1944H39.9564V16.3811H43.9965V16.9105H40.5776V18.9781H43.6076V19.4775H40.5776V21.64L44.1126 21.665Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M50.9454 20.6412H47.6679L46.9659 22.1944H46.3145L48.986 16.3811H49.6021L52.2887 22.1944H51.6373L50.9454 20.6412ZM50.7181 20.1418L49.3041 17.0204L47.8951 20.1418H50.7181Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M58.9751 22.1943L57.6317 20.3265C57.4802 20.3265 57.3237 20.3265 57.1621 20.3265H55.5814V22.1694H54.9602V16.381H57.1621C57.7947 16.3435 58.4199 16.5313 58.9246 16.9104C59.1415 17.0876 59.3144 17.3117 59.43 17.5654C59.5456 17.8191 59.6007 18.0957 59.5912 18.3737C59.6051 18.784 59.4827 19.1875 59.2427 19.5224C58.9881 19.8497 58.6341 20.0878 58.2327 20.2016L59.672 22.1993L58.9751 22.1943ZM58.4701 19.4475C58.6167 19.3077 58.7333 19.1402 58.813 18.9548C58.8926 18.7695 58.9337 18.5702 58.9337 18.3688C58.9337 18.1674 58.8926 17.968 58.813 17.7827C58.7333 17.5973 58.6167 17.4298 58.4701 17.29C58.0835 17.0106 57.6093 16.8761 57.1318 16.9104H55.5713V19.8271H57.1318C57.6126 19.8638 58.0909 19.7292 58.4802 19.4475H58.4701Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M67.5805 21.8598C67.1197 21.615 66.7368 21.2485 66.4745 20.8011C66.2131 20.3386 66.0758 19.8176 66.0758 19.2878C66.0758 18.758 66.2131 18.237 66.4745 17.7745C66.7368 17.3271 67.1197 16.9606 67.5805 16.7158C68.0687 16.4649 68.6109 16.334 69.1611 16.334C69.7114 16.334 70.2535 16.4649 70.7418 16.7158C71.2016 16.9618 71.5842 17.3281 71.8478 17.7745C72.1124 18.2378 72.2515 18.7608 72.2515 19.2928C72.2515 19.8248 72.1124 20.3478 71.8478 20.811C71.5842 21.2575 71.2016 21.6238 70.7418 21.8698C70.2535 22.1207 69.7114 22.2516 69.1611 22.2516C68.6109 22.2516 68.0687 22.1207 67.5805 21.8698V21.8598ZM70.4287 21.3604C70.7977 21.1595 71.1022 20.8602 71.3074 20.4964C71.5161 20.118 71.6254 19.6938 71.6254 19.2628C71.6254 18.8318 71.5161 18.4077 71.3074 18.0293C71.1022 17.6655 70.7977 17.3661 70.4287 17.1653C70.0403 16.9598 69.6067 16.8523 69.1662 16.8523C68.7257 16.8523 68.2921 16.9598 67.9037 17.1653C67.5329 17.3672 67.2254 17.6661 67.0148 18.0293C66.803 18.4067 66.6919 18.8312 66.6919 19.2628C66.6919 19.6945 66.803 20.119 67.0148 20.4964C67.2254 20.8596 67.5329 21.1585 67.9037 21.3604C68.293 21.5629 68.7263 21.6688 69.1662 21.6688C69.6061 21.6688 70.0394 21.5629 70.4287 21.3604Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M80.241 16.3811V22.1944H79.736L75.9434 17.4798V22.1994H75.3222V16.3811H75.8272L79.6148 21.1007V16.3811H80.241Z"
        //             fill="#2B2929"
        //         />
        //         <path d="M83.8317 16.3811H84.4528V21.665H87.7505V22.1944H83.8317V16.3811Z" fill="#2B2929" />
        //         <path
        //             d="M92.4774 20.1867V22.1844H91.8664V20.1867L89.513 16.3811H90.1746L92.1946 19.6473L94.2147 16.3811H94.8358L92.4774 20.1867Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M106.274 16.3811V22.1944H105.663V19.5125H101.982V22.1944H101.361V16.3811H101.982V18.9781H105.663V16.3811H106.274Z"
        //             fill="#2B2929"
        //         />
        //         <path d="M109.87 16.3811H110.491V22.1944H109.87V16.3811Z" fill="#2B2929" />
        //         <path
        //             d="M118.102 22.1943L116.758 20.3265C116.607 20.3265 116.45 20.3265 116.289 20.3265H114.708V22.1694H114.087V16.381H116.289C116.921 16.3435 117.547 16.5313 118.051 16.9104C118.262 17.0908 118.427 17.3165 118.536 17.5702C118.645 17.8239 118.693 18.0987 118.677 18.3737C118.691 18.784 118.569 19.1875 118.329 19.5224C118.075 19.8508 117.721 20.0891 117.319 20.2016L118.758 22.1993L118.102 22.1943ZM117.597 19.4475C117.743 19.3077 117.86 19.1402 117.94 18.9548C118.019 18.7695 118.06 18.5702 118.06 18.3688C118.06 18.1674 118.019 17.968 117.94 17.7827C117.86 17.5973 117.743 17.4298 117.597 17.29C117.21 17.0106 116.736 16.8761 116.258 16.9104H114.698V19.8271H116.258C116.739 19.8638 117.218 19.7292 117.607 19.4475H117.597Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M126 21.665V22.1944H121.839V16.3811H125.879V16.9105H122.465V18.9781H125.495V19.4775H122.465V21.64L126 21.665Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M39.7796 1.64313C40.2443 1.99938 40.6365 2.72355 40.9563 3.81562L43.6076 12.3708H47.4962L45.5166 5.93817C45.1478 4.99424 44.9132 4.00426 44.8196 2.99657C44.7869 2.72014 44.8279 2.44005 44.9387 2.18419C45.0494 1.92832 45.226 1.70562 45.4509 1.53825C45.8802 1.25358 46.7033 1.11374 47.9154 1.11374H49.6324V0.584351H36.4516V1.11374H37.3101C38.4919 1.11374 39.315 1.28854 39.7796 1.64313Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M82.9933 1.59319C83.2364 1.77756 83.4279 2.02013 83.5498 2.298C83.6717 2.57587 83.7201 2.87989 83.6903 3.18135C83.5964 4.28042 83.3796 5.3658 83.0438 6.41762L81.3319 12.3708H81.9227L84.17 4.55976C84.5639 3.14473 85.0016 2.21747 85.483 1.77797C85.9645 1.33848 86.7961 1.11707 87.9778 1.11374H89.104V0.584351H78.7816V1.11374H80.3925C81.65 1.11374 82.5287 1.27356 82.9933 1.59319Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M60.48 12.3707L62.3485 5.03917L64.5504 12.3707H67.9845L64.4393 0.794067H62.8889L59.874 12.3707H60.48Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M56.2531 26.2548L56.0561 26.934C55.6622 28.5255 55.2969 29.9755 54.9602 31.284C54.6236 32.5924 54.3155 33.8643 54.0361 35.0996C53.6758 33.4348 53.3274 31.9365 52.9907 30.6047C52.6574 29.2913 52.2483 27.8479 51.7635 26.2647H47.9052L51.6827 38.4557H53.824L56.9348 26.2697L56.2531 26.2548Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M77.3372 26.2548L76.7615 28.2075C76.2969 29.8356 75.9131 31.1491 75.6101 32.1579C75.3071 33.1668 75.0293 34.2006 74.7768 35.2594C74.2112 32.9287 73.3594 29.9322 72.2215 26.2697H68.6864L72.378 38.4557H74.3981L77.9331 26.2697L77.3372 26.2548Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M98.7042 12.3958C100.051 4.50486 103.965 0.557743 110.446 0.554414C114.769 0.554414 117.869 2.11095 119.748 5.22403C120.849 7.04526 121.625 9.43583 122.076 12.3958H125.778C125.22 9.81366 124.115 7.37785 122.536 5.249C119.869 1.76635 115.839 0.0250244 110.446 0.0250244C105.052 0.0250244 101.012 1.76635 98.3254 5.249C96.7238 7.37105 95.601 9.80809 95.0328 12.3958H98.7042Z"
        //             fill="#2B2929"
        //         />
        //         <path
        //             d="M122.111 26.2796C121.657 29.3827 120.851 31.8798 119.692 33.771C117.78 36.8941 114.679 38.4556 110.39 38.4556C103.859 38.4556 99.9465 34.3936 98.6537 26.2697H94.9419C95.4875 28.9557 96.6123 31.4938 98.2396 33.7111C100.903 37.2337 104.951 38.9933 110.385 38.99C115.819 38.9866 119.859 37.2004 122.505 33.6312C124.105 31.4295 125.223 28.9224 125.788 26.2697L122.111 26.2796Z"
        //             fill="#2B2929"
        //         />
        //     </g>
        //     <defs>
        //         <clipPath id="clip0_25_5424">
        //             <rect width="126" height="39" fill="white" />
        //         </clipPath>
        //     </defs>
        // </svg>
    );
};

export default Logo;
