import { Title, SubTitle } from './index.styles';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LayersIcon from '@mui/icons-material/Layers';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReviewsIcon from '@mui/icons-material/Reviews';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WebIcon from '@mui/icons-material/Web';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// ==============================|| MENU ITEMS ||============================== //

const MenuItems = {
    items: [
        {
            id: 'dashboard',
            type: 'group',
            children: [
                {
                    id: 'Dashboard',
                    title: <SubTitle>Dashboard</SubTitle>,
                    type: 'item',
                    url: '/dashboard',
                    icon: IconDashboard,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'management',
            title: <Title>Management</Title>,
            type: 'group',
            children: [
                {
                    id: 'menu',
                    title: "Menu's",
                    type: 'item',
                    icon: LayersIcon,
                    url: '/menu/list',
                    breadcrumbs: true
                },
                {
                    id: 'page-management',
                    title: <SubTitle>Page Management</SubTitle>,
                    type: 'item',
                    icon: WebIcon,
                    url: '/page-management/list',
                    breadcrumbs: true
                },
                {
                    id: 'email-template',
                    title: <SubTitle>Email Template</SubTitle>,
                    type: 'item',
                    icon: MailOutlineIcon,
                    url: '/email-template/list',
                    breadcrumbs: true
                },
                {
                    id: 'discounts',
                    title: <SubTitle>Discounts</SubTitle>,
                    type: 'item',
                    icon: PaidOutlinedIcon,
                    url: '/discounts/list',
                    breadcrumbs: true
                },
                {
                    id: 'orders',
                    title: <SubTitle>Orders</SubTitle>,
                    type: 'item',
                    icon: InventoryIcon,
                    url: '/orders/list',
                    breadcrumbs: true
                },
                {
                    id: 'reviews',
                    title: <SubTitle>Reviews</SubTitle>,
                    type: 'item',
                    icon: StarBorderIcon,
                    url: '/reviews/list',
                    breadcrumbs: true
                },
                {
                    id: 'reports',
                    title: <SubTitle>Reports</SubTitle>,
                    type: 'item',
                    icon: WarningAmberIcon,
                    url: '/reports/list',
                    breadcrumbs: true
                },
                {
                    id: 'notifications',
                    title: <SubTitle>Notifications</SubTitle>,
                    type: 'item',
                    icon: NotificationsNoneIcon,
                    url: '/notifications/list',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'user',
            title: <Title>User Management</Title>,
            type: 'group',
            children: [
                {
                    id: 'app-users',
                    title: <SubTitle>App Users</SubTitle>,
                    type: 'item',
                    icon: MobileFriendlyIcon,
                    url: '/app-user/list',
                    breadcrumbs: true
                },
                {
                    id: 'admins',
                    title: <SubTitle>Admin Management</SubTitle>,
                    type: 'item',
                    icon: GroupIcon,
                    url: '/admin/list',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'faq',
            type: 'group',
            children: [
                {
                    id: 'faq-list',
                    title: <SubTitle>FAQs</SubTitle>,
                    type: 'item',
                    icon: QuestionMarkIcon,
                    url: '/faq/list',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'testimonials',
            type: 'group',
            children: [
                {
                    id: 'Testimonials',
                    title: <SubTitle>Testimonials</SubTitle>,
                    type: 'item',
                    url: '/testimonials/list',
                    icon: ReviewsIcon,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'products-management',
            title: <Title>Products Management</Title>,
            type: 'group',
            children: [
                {
                    id: 'Categories',
                    title: <SubTitle>Categories</SubTitle>,
                    type: 'item',
                    icon: ListAltIcon,
                    url: '/categories/list',
                    breadcrumbs: true
                },
                {
                    id: 'Designers',
                    title: <SubTitle>Designers</SubTitle>,
                    type: 'item',
                    icon: PersonIcon,
                    url: '/designers/list',
                    breadcrumbs: true
                },
                {
                    id: 'listed-items',
                    title: <SubTitle>Listed Items</SubTitle>,
                    type: 'item',
                    icon: CheckroomIcon,
                    url: '/listed-items/list',
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default MenuItems;
