import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation ($input: LoginAdminDTO!) {
        login(input: $input) {
            admin {
                _id
                firstname
                lastname
                email
                phone
                role
                status
            }
            refreshToken
            accessToken
        }
    }
`;

export const REGISTER = gql`
    mutation ($input: CreateAdminDTO!) {
        register(input: $input) {
            _id
            firstname
            lastname
            email
            status
            role
            phone
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation ($input: String!) {
        forgotPassword(input: $input)
    }
`;

export const RESET_PASSWORD = gql`
    mutation ($input: ResetPasswordDTO!) {
        resetPassword(input: $input)
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation ($input: ChangePasswordDTO!) {
        changePassword(input: $input) {
            message
            status
        }
    }
`;
export const REFRESH_TOKEN = gql`
    mutation RefreshToken($refreshToken: String!) {
        refresh(refreshToken: $refreshToken) {
            accessToken
            refreshToken
        }
    }
`;
