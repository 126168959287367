export const truncateString = (str, num) => {
    if (str.length > num) {
        return `${str.slice(0, num)}...`;
    }
    return str;
};

export const firstLetterUppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const googleAutocompleteStyles = {
    height: '55px',

    '& input': {
        width: '100%',
        height: '100%',
        padding: '14px 16px 18px 16px',
        fontSize: '16px',
        '&:focus': {
            border: '2px solid #e1b8b2',
            outline: 'none'
        }
    },
    '&:before': {
        border: 'none !important'
    }
};

export const infoStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '3px',
    textTransform: 'capitalize',
    color: '#2B2929',
    marginBottom: '15px'
};

export const hasDecimal = (number) => {
    return number !== Math.floor(number);
};

export const displayBusinessNameOrUserName = (firstName, lastName, businessName, showLastName: boolean = true) => {
    if (businessName) {
        return businessName;
    }
    if (firstName && lastName) {
        if (showLastName) {
            return `${firstName} ${lastName}`;
        }
        return firstName;
    }
    return '';
};

export const displayUserName = (firstName, lastName, showLastName: boolean = true) => {
    if (firstName && lastName) {
        if (showLastName) {
            return `${firstName} ${lastName}`;
        }
        return firstName;
    }
    return '';
};
