import { gql } from '@apollo/client';

export const GET_ALL_NOTIFICATIONS = gql`
    query ($input: GetNotificationsDTO!) {
        GetAllAdminNotifications(input: $input) {
            notifications {
                notificationTopic
                notificationMessage
                isRead
                date
                redirectTo
                userData {
                    image
                    businessName
                    firstName
                    lastName
                }
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;
