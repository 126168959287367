import styled from 'styled-components';

export const Title = styled.span`
    margin: 0 0.1rem;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: #212121;
    font-style: normal;
`;

export const SubTitle = styled.span`
    margin: 0 0.1rem;
    font-size: 14px;
    font-weight: 500;
    color: #2b2929;
    font-style: normal;
`;
